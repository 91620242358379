import React from 'react'
import DeveloperResize from '../assets/DeveloperResize.jpeg'
import Cloud1Resize from '../assets/Cloud1Resize.jpeg'

const About = () => {
  return (
    <div name='about' className='lg:w-100% h-100%  bg-[#002436] text-gray-200'>
        <div className='flex flex-col justify-center items-center w-full h-full'>
            <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
                <div className='sm:text-right pb-8 pl-4'>
                    <p className=' text-4xl font-bold inline border-b-4 border-[#38afeb]'>
                        About
                    </p>
                </div>
                <div></div>
            </div>
                <div className='max-w-[1000px] w-full grid lg:grid-cols-2 gap-8 px-4'>
                    <div className='sm:text-4xl font-bold'>
                        <p className='text-slate-400'>Welcome.</p>
                        <p>This website showcases my portfolio of projects, and my passion to build applications to solve real world problems.</p>
                        
                    </div>
                    <div>
                        <img src={DeveloperResize} alt='Developer' className='w-full h-full rounded-xl' />
                    </div>
                    <div className=''>
                        <p>
                        I am full stack developer with a passion for creating dynamic, user-friendly web applications.  
                        I have experience with a variety of programming languages, including JavaScript, TypeScript, Python, and Java.  I am also 
                        familiar with a variety of frameworks and libraries, including React, Next.js, Node.js, Express.js, and Tailwind CSS.  
                        I have developed applications using cloud services such as AWS, Vercel, and Firebase,
                        </p>
                    </div>
                    <div>
                        <img src={Cloud1Resize} alt='Developer' className='w-full h-full rounded-xl pb-36' />
                    </div>
                </div>
        </div>
    </div>
  )
}

export default About