import React from 'react'
import { useState } from 'react'
import {FaBars, FaTimes, FaGithub, FaLinkedin} from 'react-icons/fa'
import { HiOutlineMail } from 'react-icons/hi'
import {BsFillPersonLinesFill} from 'react-icons/bs'
import logo from '../assets/logo.png'
import { Link } from 'react-scroll'

export const Navbar = () => {

    const [showNav, setShowNav] = useState(false)
    const handleClick = () => {
        setShowNav(!showNav)
    }

  return (
    <div className='fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#002436] text-gray-200'>
        <div>
            <img src={logo} alt='logo' style={{width: '130px'}}/>
        </div>

        {/* menu */}
        <ul className='hidden md:flex'>
            <li className='hover:text-blue-300'><Link to="home" smooth={true} offset={0} duration={500}>Home</Link></li>
            <li className='hover:text-blue-300'><Link to="about" smooth={true} offset={-80} duration={500}>About</Link></li>
            <li className='hover:text-blue-300'><Link to="skills" smooth={true} offset={-80} duration={500}>Skills</Link></li>
            <li className='hover:text-blue-300'><Link to="work" smooth={true} offset={-200} duration={500}>Work</Link></li>
            <li className='hover:text-blue-300'><Link to="contact" smooth={true} offset={-30} duration={500}>Contact</Link></li>
            </ul>
        


        {/* Hamburger */}
        <div onClick={handleClick} className='md:hidden z-10'>
            {!showNav ? <FaBars /> : <FaTimes />}
        </div>



        {/* Mobile Menu */}
        <ul className={!showNav ? 'hidden' : 'absolute top-0 left-0 w-full h-screen bg-[#002436] flex flex-col justify-center items-center'}>
            <li className='py-7 text-4xl hover:text-blue-300'><Link onClick={handleClick} to="home" smooth={true} offset={0} duration={500}>Home</Link></li>
            <li className='py-7 text-4xl hover:text-blue-300'><Link onClick={handleClick} to="about" smooth={true} offset={-80} duration={500}>About</Link></li>
            <li className='py-7 text-4xl hover:text-blue-300'><Link onClick={handleClick} to="skills" smooth={true} offset={-70} duration={500}>Skills</Link></li>
            <li className='py-7 text-4xl hover:text-blue-300'><Link onClick={handleClick} to="work" smooth={true} offset={-80} duration={500}>Work</Link></li>
            <li className='py-7 text-4xl hover:text-blue-300'><Link onClick={handleClick} to="contact" smooth={true} offset={-80} duration={500}>Contact</Link></li>
        </ul>



        {/* Social Icons */}
        <div className='hidden lg:flex fixed flex-col top-[35%] left-0'>
            <ul>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-black-700'>
                    <a  className='flex justify-between items-center w-full text-gray-200'
                    href="https://github.com/AshyLarryM">
                        Github <FaGithub size={30} />
                    </a>
                </li>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-700'>
                    <a  className='flex justify-between items-center w-full text-gray-200'
                    href="https://www.linkedin.com/in/lawrence-markel-9687a721b/">
                        LinkedIn <FaLinkedin size={30} />
                    </a>
                </li>
                {/* <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#38afeb]'>
                    <a  className='flex justify-between items-center w-full text-gray-200'
                    href="/">
                        Email <BsFillPersonLinesFill size={30} />
                    </a>
                </li> */}
                {/* <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#37b86f]'>
                    <a  className='flex justify-between items-center w-full text-gray-200'
                    href="/">
                        Email <HiOutlineMail size={30} />
                    </a>
                </li> */}
               
                
            </ul>

        </div>
    </div>
  )
}
