import React from 'react'
import {HiArrowNarrowRight} from 'react-icons/hi'
import { Link } from 'react-scroll'

export const Home = () => {
  return (
    <div name='home' className='w-full h-screen bg-[#002436]'>

      {/* Container */}
      <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full'>
        <p className='text-[#38afeb] text-2xl'>Hi, my name is</p>
        <h1 className='text-4xl sm:text-6xl font-bold text-[#d9effa]'>Lawrence Markel</h1>
        <h2 className='text-4xl sm:text-6xl font-bold text-[#9eadb5]'>A Full Stack Developer.</h2>
        <p className='text-[#9eadb5] py-4 max-w-[700px]'>I am a Full-Stack developer with a passion for creating beautiful, functional websites and applications.</p>
        
        <div>
        <Link to="work" smooth={true} offset={50} duration={500}><button className='text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-[#38afeb] hover:border-[#38afeb]'>View Work
          <span className='group-hover:rotate-90 duration-300'>
            <HiArrowNarrowRight className='ml-4' />
          </span>
          </button>
        </Link>
        </div>
      </div>

    </div>
  )
}
