import React from 'react'


const Contact = () => {
  return (
    <div name='contact' className='w-full h-screen bg-[#002436] flex justify-center items-center p-4'>
        <form method='POST' action="https://getform.io/f/0b05224b-4424-4a85-a058-83eb0933efc6" className='flex flex-col max-w-[600px] w-full'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 border-[#38afeb] text-gray-200'>Contact</p>
                <p className='text-gray-200 py-4'>Submit the form below, or email me at: LarryM093@gmail.com</p>
            </div>
            <input className='bg-slate-500 p-2 rounded-xl text-white' type="text" placeholder='Name' name='name' />
            <input className='my-4 p-2 bg-slate-500 rounded-xl text-white' type="email" placeholder='Email' name='email' />
            <textarea className='bg-slate-500 py-2 rounded-xl text-white p-2' placeholder='Services requested' name="message" rows="10"></textarea>
            <button className='text-white border-2 hover: hover:bg-[#38afeb] hover:border-[#38afeb] px-4 py-3 my-8 mx-auto flex items-center'>Submit</button>
        </form>
    </div>
  )
}

export default Contact