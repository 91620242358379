import React from 'react'
import cookoutResize from '../assets/cookoutResize.png'
import farinellaResize from '../assets/farinellaResize.png'
import weatherApp from '../assets/weatherApp.png'
import nextBlog from '../assets/nextBlog.png'
import Quoteapp from '../assets/Quoteapp.png'
import climbon from '../assets/climbon.png'
import gameapp from '../assets/gameapp.png'

const Work = () => {
  return (
    <div name='work' className='w-full md:h-screen text-gray-200 bg-[#002436] lg:mb-20'>
        <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 text-gray-200 border-[#38afeb]'>
                    Work
                </p>
                <p className='py-6'>Check out some of my recent work.</p>
                <p>Hover over each project to view deployment or public repository.</p>
            </div>

            {/* container */}
            <div className='grid sm:grid-cols-2 md:grid-cols-2 gap-4'>
                {/* Grid */}
                <div style={{backgroundImage: `url(${gameapp})`}} 
                className='shadow-lg shadow-black group container rounded-md flex justify-center items-center mx-auto content-div'>
                    
                    {/* Hover Effects */}
                    <div className='opacity-0 group-hover:opacity-100'>
                        <span className='text-2xl font-bold text-white tracking-wider'>
                            NerveLab.com - Next.js Application
                        </span>
                        <div className='pt-8 text-center'>
                            <a href='https://nervelab.vercel.app/'>
                                <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-600 font-bold text-lg'>Demo</button>
                            </a>
                            <a href='https://github.com/AshyLarryM/nervelab'>
                                <button className='text-center rounded-lg px-4 py-3 bg-white text-gray-600 font-bold text-lg'>Code</button>
                            </a>
                        </div> 
                    </div>
                </div>
                 <div style={{backgroundImage: `url(${climbon})`}} 
                className='shadow-lg shadow-black group container rounded-md flex justify-center items-center mx-auto content-div'>
                    
                    {/* Hover Effects */}
                    <div className='opacity-0 group-hover:opacity-100'>
                        <span className='text-2xl font-bold text-white tracking-wider'>
                            ClimbOn Training - Next.js
                        </span>
                        <div className='pt-8 text-center'>
                            <a href='https://prisma-planetscale-climbing.vercel.app'>
                                <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-600 font-bold text-lg'>Demo</button>
                            </a>
                            <a href='https://github.com/AshyLarryM/ClimbOn'>
                                <button className='text-center rounded-lg px-4 py-3 bg-white text-gray-600 font-bold text-lg'>Code</button>
                            </a>
                        </div> 
                    </div>
                </div>
               
                <div style={{backgroundImage: `url(${cookoutResize})`}} 
                className='shadow-lg shadow-black group container rounded-md flex justify-center items-center mx-auto content-div'>
                    
                    {/* Hover Effects */}
                    <div className='opacity-0 group-hover:opacity-80'>
                        <span className='text-2xl font-bold text-white tracking-wider'>
                            React JS Application
                        </span>
                        <div className='pt-8 text-center'>
                            <a href='https://social-cooking-51af9.firebaseapp.com/'>
                                <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-600 font-bold text-lg'>Demo</button>
                            </a>
                            <a href='https://github.com/AshyLarryM/Cookout-Recipe-Sharing'>
                                <button className='text-center rounded-lg px-4 py-3 bg-white text-gray-600 font-bold text-lg'>Code</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div style={{backgroundImage: `url(${nextBlog})`}} 
                className='shadow-lg shadow-black group container rounded-md flex justify-center items-center mx-auto content-div'>
                    
                    {/* Hover Effects */}
                    <div className='opacity-0 group-hover:opacity-100'>
                        <span className='text-2xl font-bold text-white tracking-wider'>
                            Next.js Blog
                        </span>
                        <div className='pt-8 text-center'>
                            <a href='https://blog-app-next-demo.vercel.app/'>
                                <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-600 font-bold text-lg'>Demo</button>
                            </a>
                            <a href='https://github.com/AshyLarryM/blog-app-next-demo'>
                                <button className='text-center rounded-lg px-4 py-3 bg-white text-gray-600 font-bold text-lg'>Code</button>
                            </a>
                        </div> 
                    </div>
                </div>
                <div style={{backgroundImage: `url(${farinellaResize})`}} 
                className='shadow-lg shadow-black group container rounded-md flex justify-center items-center mx-auto content-div'>
                    
                    {/* Hover Effects */}
                    <div className='opacity-0 group-hover:opacity-100'>
                        <span className='text-2xl font-bold text-white tracking-wider'>
                            React JS Application
                        </span>
                        <div className='pt-8 text-center'>
                            <a href='https://www.farinellaslawnandlandscape.com/'>
                                <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-600 font-bold text-lg'>Demo</button>
                            </a>
                            <a href='https://github.com/AshyLarryM/Farinellas-Lawn-and-Landscape-LLC-Application'>
                                <button className='text-center rounded-lg px-4 py-3 bg-white text-gray-600 font-bold text-lg'>Code</button>
                            </a>
                        </div> 
                    </div>
                </div>
                <div style={{backgroundImage: `url(${weatherApp})`}} 
                className='shadow-lg shadow-black group container rounded-md flex justify-center items-center mx-auto content-div'>
                    
                    {/* Hover Effects */}
                    <div className='opacity-0 group-hover:opacity-100'>
                        <span className='text-2xl font-bold text-white tracking-wider'>
                            React JS Application
                        </span>
                        <div className='pt-8 text-center'>
                            <a href='https://lmweatherapp.s3.amazonaws.com/index.html'>
                                <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-600 font-bold text-lg'>Demo</button>
                            </a>
                            <a href='https://github.com/AshyLarryM/Weather-app'>
                                <button className='text-center rounded-lg px-4 py-3 bg-white text-gray-600 font-bold text-lg'>Code</button>
                            </a>
                        </div> 
                    </div>
                </div>  
            </div>
        </div>
    </div>
  )
}

export default Work